import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthService } from '../../services/auth.service';
import { GoogleSignInService } from '../../services/google-sign-in.service';

@Component({
  selector: 'cwk-sign-in-out',
  templateUrl: './sign-in-out.component.html',
  styleUrls: ['./sign-in-out.component.scss']
})
export class SignInOutComponent implements OnInit {

  isExclamationMarkShown = true;

  constructor(
    private authService: AuthService,
    private googleSignInService: GoogleSignInService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  ngOnInit() {

    // Show exclamation mark after name for english locale
    this.isExclamationMarkShown = !this.locale || (this.locale.substring(0, 2) === 'en');

    // Initialize Google Sign-in service
    this.googleSignInService.initialize();
  }

  // Unauthenticated user wants to create new account
  createAccount() {
    window.location.href = environment.urlRoot + '/create-account' + window.location.search;
  }

  // Get name of the currently signed in user
  getName() {
    const sessionInfo = this.authService.getSessionInfo();
    return !sessionInfo ? '' :
      (sessionInfo.FirstName + (sessionInfo.LastName ? (' ' + sessionInfo.LastName) : ''));
  }

  isSignedIn() {
    return this.authService.isAuthenticated();
  }

  signIn() {

    // Sign in
    this.authService.signIn('signin');
  }

  signOut() {

    // Sign out - navigate to sign-out URL
    this.authService.signOut()
      .subscribe(() => { window.location.href = environment.urlSignOut/* + window.location.search*/; });
  }
}
