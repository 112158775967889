import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { FacebookConversionInfo } from '../interfaces/facebook-conversion-info';
// import { HubSpotDealInfo } from '../interfaces/hub-spot-deal-info';
// import { HubSpotSubscriberInfo } from '../interfaces/hub-spot-subscriber-info';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackingPixelDataService {

  private urlFacebook = environment.urlWebServices + '/cwk/facebook';
  // private urlHubSpot = environment.urlWebServices + '/cwk/hub-spot';

  constructor(private http: HttpClient) { }

  // Create a new subscriber
  /*createSubscriber(payload: HubSpotSubscriberInfo): Observable<any> {

    return this.http.post(`${this.urlHubSpot}/subscriber`, payload)
      .pipe(
        catchError(error => observableThrowError(error.message || error))
      );
  }*/

  // Track the deal
  /*trackDeal(payload: HubSpotDealInfo): Observable<any> {

    return this.http.post(`${this.urlHubSpot}/deal`, payload)
      .pipe(
        catchError(error => observableThrowError(error.message || error))
      );
  }*/

  // Track the Facebook conversion
  trackFacebookConversion(payload: FacebookConversionInfo): Observable<any> {

    return this.http.post(`${this.urlFacebook}/conversion-event`, payload)
      .pipe(
        catchError(error => observableThrowError(error.message || error))
      );
  }
}
