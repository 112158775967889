import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Credentials } from '../interfaces/credentials';
import { environment } from 'environments/environment';

@Injectable()
export class AuthHttpService {

  private urlAuth = environment.urlWebServices + '/api/auth';

  constructor(private http: HttpClient) { }

  // Create a new account
  createAccount(credentials: Credentials): Observable<any> {
    return this.http.post(this.urlAuth + '/create-account', this.fixCredentialsToMySQL(credentials));
  }

  // Link external provider credentials with an existing account
  linkCredentials(credentials: Credentials): Observable<any> {
    return this.http.post(this.urlAuth + '/link-credentials', credentials);
  }

  // Resend verification email
  resendVerificationEmail(email: string): Observable<any> {
    return this.http.post(this.urlAuth + '/verification-email', email);
  }

  // Verify credentials and create new session
  signIn(credentials: Credentials): Observable<any> {
    return this.http.post(this.urlAuth + '/sign-in', credentials);
  }

  // Call sign out web service
  signOut(uuid): Observable<any> {
    return this.http.get(this.urlAuth + '/sign-out');
  }

  // Verify account
  verifyAccount(key: string): Observable<any> {
    return this.http.post(this.urlAuth + '/verify-account', key);
  }

  private fixCredentialsToMySQL(credentials: Credentials): any {
    const payload = <any>structuredClone(credentials);
    payload.IsActive = +payload.IsActive;
    return payload;
  }
}
