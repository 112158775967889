import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  /* tslint:disable */
  readonly DEFAULT_DESCRIPTION = 'Coding with Kids teaches computer programming to elementary and middle school children. We offer Coder\'s Ladder classes, enrichment classes, and camps.';
  readonly DEFAULT_KEYWORDS = 'Coding with Kids, Coder’s Ladder®, Coder’s Pathways®, Summer STEM camps, After school Coding camps, Live Online Coding Camps, Coding Camps Near Me';
  readonly DEFAULT_ROBOTS = 'all';
  readonly DEFAULT_TITLE = 'Coding with Kids';
  /* tslint:enable */

  constructor(
    private meta: Meta,
    private title: Title
  ) {
    this.meta.updateTag({ name: 'og:type', content: 'website' });
  }

  updateDescription(description: string) {
    this.meta.updateTag({ name: 'description', content: description || this.DEFAULT_DESCRIPTION });
    this.meta.updateTag({ property: 'og:description', content: description || this.DEFAULT_DESCRIPTION });
  }

  updateKeywords(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: (keywords || this.DEFAULT_KEYWORDS) });
  }

  updateRobots(robots: string) {
    this.meta.updateTag({ name: 'robots', content: (robots || this.DEFAULT_ROBOTS) });
  }

  updateTitle(title: string) {
    this.title.setTitle((title || this.DEFAULT_TITLE));
    this.meta.updateTag({ property: 'og:title', content: (title || this.DEFAULT_TITLE) });
  }

  updateUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url });
  }
}
