<form #loginForm="ngForm" (ngSubmit)="submit()">
  <div class="modal-header">
    <h1 *ngIf="mode === 'signin'" i18n>Please, log in or create your account</h1>
    <h1 *ngIf="mode === 'signup-required'" i18n>Please, log in or create your account</h1>
    <h1 *ngIf="mode === 'refresh-session'" i18n>Your session expired, login to continue</h1>
    <h1 *ngIf="mode === 'routing'" i18n>Login is required to continue</h1>
  </div>
  <div class="modal-body">

    <!-- LOGIN STAGE -->
    <div *ngIf="stage === 'LOGIN'" [@.disabled]="isAnimationPaused" [@slideInOut]>
      <h2 i18n>Enter your credentials:</h2>

      <!-- Email -->
      <div class="form-group">
        <label for="email" i18n>Email:</label>
        <input type="email" id="email" class="form-control" name="email" [ngClass]="{'cwk-form-control-required': !credentials.Email, 'cwk-form-control-email': credentials.Email && email.errors && (email.dirty || email.touched) && email.errors['email']}" [(ngModel)]="credentials.Email" placeholder="Email" #email="ngModel" email required>
      </div>

      <!-- Password -->
      <div class="form-group">
        <label for="password" i18n>Password:</label>
        <input type="password" id="password" class="form-control" name="password" [ngClass]="{'cwk-form-control-required': !credentials.Password}" [(ngModel)]="credentials.Password" placeholder="Password" required>
      </div>

      <!-- Remember my email -->
      <div class="form-check">
        <label class="form-check-label">
          <input type="checkbox" id="remember" class="form-check-input" name="remember" [(ngModel)]="rememberEmail">
          <span i18n>Remember my user name</span>
        </label>
      </div>

      <!-- Error occured -->
      <div class="row" *ngIf="status">
        <div class="col-12">
          <p class="text-center text-danger" *ngIf="status === 'WAIT'">
            <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>&nbsp;<ng-container i18n>Verifying the credentials</ng-container>
          </p>
          <p class="text-center text-danger" *ngIf="status !== 'WAIT'">
            <ng-container *ngIf="status === 'USE-GOOGLE'">The email address is linked to Google credentials.<br/>Please sign in with Google using the button below!</ng-container>

            <ng-container *ngIf="status === 'VERIFY-EMAIL'">
              <ng-container *ngIf="!errorResendingVerification && !isVerificationResent">
                The account with the email address exists but is not verified yet.<br/>
                <span *ngIf="!isResendingVerification"><a class="cwk-link" (click)="resendVerificationEmail()">Resend the verification email</a>.</span>
                <span class="cwk-blue-link"*ngIf="isResendingVerification"><fa-icon class="me-1" [icon]="faSpinner" [spin]="true"></fa-icon>Resending the verification email...</span>
              </ng-container>
              <ng-container *ngIf="!errorResendingVerification && isVerificationResent">
                The email with the verification link has been sent to your email address. If you don't see the email, check your Spam folder.
              </ng-container>
              <ng-container *ngIf="errorResendingVerification">
                Error occured while resending the email with the verification link: {{ errorResendingVerification }}
              </ng-container>
            </ng-container>

            <ng-container *ngIf="!['USE-GOOGLE', 'VERIFY-EMAIL'].includes(status)">{{ status }}</ng-container>
          </p>
        </div>
      </div>

      <p>
        <span class="float-start">
          <a class="cwk-link" (click)="goResetPassword()" i18n>I forgot password</a>
        </span>
        <span class="float-end" *ngIf="(mode === 'signin') || (mode === 'signup-required')">
          <a class="cwk-link" (click)="goCreateAccount()" i18n>Create account</a>
        </span>
      </p>
    </div>

    <!-- MERGE-ACCOUNTS STAGE -->
    <div *ngIf="stage === 'MERGE-ACCOUNTS'" [@.disabled]="isAnimationPaused" [@slideInOut]>
      <p i18n>
        The email address is already used for existing internal account. Enter your Coding with Kids account password to link the account with the {{ credentials.AuthProvider | titlecase }} credentials:
      </p>

      <!-- Email -->
      <div class="form-group">
        <label for="email" i18n>Email:</label>
        <input type="email" id="email" class="form-control" disabled name="email" [ngClass]="{'cwk-form-control-required': !credentials.Email, 'cwk-form-control-email': credentials.Email && email.errors && (email.dirty || email.touched) && email.errors['email']}" [(ngModel)]="credentials.Email" placeholder="Email" #email="ngModel" email required>
      </div>

      <!-- Password -->
      <div class="form-group">
        <label for="password" i18n>Password:</label>
        <input type="password" id="password" class="form-control" name="password" [ngClass]="{'cwk-form-control-required': !credentials.Password}" [(ngModel)]="credentials.Password" placeholder="Password" required>
      </div>

      <!-- Error occured -->
      <div class="row" *ngIf="status">
        <div class="col-12">
          <p class="text-center text-danger" *ngIf="status === 'WAIT'">
            <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>&nbsp;<ng-container i18n>Verifying the credentials</ng-container>
          </p>
          <p class="text-center text-danger" *ngIf="status !== 'WAIT'">
            {{ status }}
          </p>
        </div>
      </div>

      <p>
        <a class="cwk-link" (click)="goResetPassword()" i18n>I forgot password</a>
      </p>
    </div>

    <!-- SIGN-UP STAGE -->
    <div *ngIf="stage === 'SIGN-UP'" [@.disabled]="isAnimationPaused" [@slideInOut]>
      <div class="my-3" i18n>
        There is no account associated with the {{ credentials.AuthProvider | titlecase }} credentials. If you continue, your Coding with Kids account will be created using this information received from {{ credentials.AuthProvider | titlecase }} authentication provider:
        <ul class="mt-3">
          <li>Email: <b>{{ credentials.Email }}</b></li>
          <li>First name: <b>{{ credentials.FirstName }}</b></li>
          <li>Last name: <b>{{ credentials.LastName }}</b></li>
        </ul>
      </div>

      <!-- Error occured -->
      <div class="row" *ngIf="status">
        <div class="col-12">
          <p class="text-center text-danger" *ngIf="status === 'WAIT'">
            <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>&nbsp;<ng-container i18n>Creating account</ng-container>
          </p>
          <p class="text-center text-danger" *ngIf="status !== 'WAIT'">
            {{ status }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">

    <!-- SOCIAL LOGIN -->
    <div class="me-auto" [hidden]="stage !== 'LOGIN'" [@.disabled]="isAnimationPaused" [@slideInOut]>
      <div #googleSignInButton [hidden]="!credentials.Email.endsWith('@codingwithkids.com')"></div>
    </div>

    <!-- Buttons -->
    <button type="button" class="btn btn-warning btn-large" (click)="cancel()" i18n>Cancel</button>
    <div *ngIf="stage === 'LOGIN'" [@.disabled]="isAnimationPaused" [@slideInOut]>
      <button type="submit" class="btn btn-warning btn-large" [disabled]="(!loginForm.form.valid) || (status === 'WAIT')" i18n>Login</button>
    </div>
    <div *ngIf="stage === 'MERGE-ACCOUNTS'" [@.disabled]="isAnimationPaused" [@slideInOut]>
      <button type="submit" class="btn btn-warning btn-large" [disabled]="(!loginForm.form.valid) || (status === 'WAIT')" i18n>Link to {{ credentials.AuthProvider | titlecase }}</button>
    </div>
    <div *ngIf="stage === 'SIGN-UP'" [@.disabled]="isAnimationPaused" [@slideInOut]>
      <button type="submit" class="btn btn-warning btn-large" [disabled]="(!loginForm.form.valid) || (status === 'WAIT')" i18n>Create Account</button>
    </div>
  </div>
</form>

