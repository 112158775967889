<div class="page-footer">

  <div class="row">
    <div class="col-lg-5 col-12">
      <!--div class="mt-1 mt-lg-0 d-flex justify-content-center justify-content-lg-start"-->

        <!-- Facebook -->
        <!--div class="fb-like" data-href="https://www.codingwithkids.com" data-layout="button_count" data-action="like" data-size="small" data-show-faces="false" data-share="true"></div-->

        <!-- Twitter -->
        <!--a href="https://twitter.com/share" class="cwk-link cwk-blue-link twitter-share-button" data-url="http://www.codingwithkids.com" data-via="codingwithkids" data-related="codingwithkids" data-hashtags="codingwithkids">Tweet</a>
      </div-->
    </div>

    <!-- Coding with Kids -->
    <div class="col-lg-2 col-12 text-center">
      <!--16455 NE 85th Street, Redmond, WA 98052, Suite 201<br/-->
      <div>Tax ID: 45-4779162</div>
      <div class="mt-1">Icons provided by <a class="cwk-link cwk-blue-link" href="https://icons8.com" target="_blank">icons8</a></div>
    </div>

    <div class="col-lg-5 col-12">
      <div class="d-flex flex-wrap mt-1 mt-lg-0 justify-content-center justify-content-lg-end">
        <a class="me-3 me-lg-0 ms-0 ms-lg-3 cwk-link cwk-blue-link" href="{{ urlRoot + '/faqs' }}">FAQs</a>
        <a class="me-3 me-lg-0 ms-0 ms-lg-3 cwk-link cwk-blue-link" href="{{ urlRoot + '/privacy-policy' }}">Privacy Policy</a>
        <a class="me-3 me-lg-0 ms-0 ms-lg-3 cwk-link cwk-blue-link" href="{{ urlRoot + '/terms-of-service' }}">Terms of Service</a>
        <a class="me-3 me-lg-0 ms-0 ms-lg-3 cwk-link cwk-blue-link" href="{{ urlRoot + '/contact' }}">Contact Us</a>
        <a class="me-3 me-lg-0 ms-0 ms-lg-3 cwk-link cwk-blue-link" href="{{ urlRoot + '/jobs' }}">Jobs</a>
        <span class="ms-0 ms-lg-3 cwk-link cwk-blue-link" (click)="onClickManageCookies()">Privacy Settings</span>
      </div>

      <div class="mt-1 d-flex justify-content-center justify-content-lg-end">
        <span>&copy; {{ now | date:'YYYY' }}, Coding with Kids &reg;</span>
      </div>
    </div>
  </div>
</div>
