<div>
  <span *ngIf="!isSignedIn()">
    <span class="cwk-link" (click)="signIn()" i18n>Login</span> |
    <span class="cwk-link" (click)="createAccount()" i18n>Create account</span>
  </span>
  <span *ngIf="isSignedIn()">
    <ng-container i18n>Welcome</ng-container> {{ getName() }}<span *ngIf="isExclamationMarkShown">!</span>
    <span class="ms-2" *ngIf="!isExclamationMarkShown">|</span>
    <span class="cwk-link" (click)="signOut()" i18n>&#32;Logout</span>
  </span>
</div>

