import { Currencies } from '../classes/currencies';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { ReplaySubject, of } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';

export interface GeolocationData {
  city: string;
  country_code: string;
  country_name: string;
  currency: string;
  ip: string;
  latitude: number;
  longitude: number;
  metro_code: number;
  region_code: string;
  region_name: string;
  time_zone: string;
  zip_code: string;
}

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  // USE TO DEBUG DIFFERNT LOCATIONS
  // private urlReallyFreeGeoIp = 'https://reallyfreegeoip.org/json/139.99.178.34'; // AU
  // private urlReallyFreeGeoIp = 'https://reallyfreegeoip.org/json/192.206.151.131'; // CA
  // private urlReallyFreeGeoIp = 'https://reallyfreegeoip.org/json/196.245.163.202';  // GB
  // private urlReallyFreeGeoIp = 'https://reallyfreegeoip.org/json/20.43.236.179'; // US - California, San Jose
  // private urlReallyFreeGeoIp = 'https://reallyfreegeoip.org/json/40.97.117.131'; // US - Washington
  // private urlReallyFreeGeoIp = 'https://reallyfreegeoip.org/json/10.0.1.21'; // Local IP

  // Default info
  private defaultInfo: GeolocationData = {
    city: '',
    country_code: 'US',
    country_name: 'United States',
    currency: 'USD',
    ip: '',
    latitude: 47.6092,
    longitude: -122.3314,
    metro_code: 0,
    region_code: 'WA',
    region_name: 'Washington',
    time_zone: 'America/Los_Angeles',
    zip_code: ''
  };

  // Retrieved geolocation info
  private info = null;
  private refreshGeolocation = new ReplaySubject<any>(1);

  // Public geolocation information provider
  private urlReallyFreeGeoIp = 'https://reallyfreegeoip.org/json/';

  // Timeout
  private TIMEOUT = 3000;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {

    // Initialize geolocation
    this.getGeolocation()
      .subscribe(
        (value) => {
          this.info = value;
          this.updateObservable();
        },
        (error) => {

          // Fallback to the default info
          this.info = this.defaultInfo;
          this.updateObservable();
        }
      );
  }

  // Get the default info
  getDefaultInfo(): GeolocationData {
    return this.defaultInfo;
  }

  // Get the current info
  getInfo(): GeolocationData {
    return this.info;
  }

  // Get the observable of geolocation
  getObservable() {
    return this.refreshGeolocation.asObservable();
  }

  setCurrency(currency: any) {

    // Set the new currency
    this.getInfo().currency = currency;

    // Store currency into local storage
    this.localStorageService.setItem('currency', currency);

    // Update observable so subscribers know the change
    this.updateObservable();
  }

  // Broadcast geolocation change
  private updateObservable() {
    this.refreshGeolocation.next(this.info);
  }

  // Retrieve geolocation from external web service
  private getGeolocation(): Observable<GeolocationData> {

    // Call the web service
    return this.http.get<GeolocationData>(`${this.urlReallyFreeGeoIp}`)
      .pipe(
        timeout({ first: this.TIMEOUT, with: () => of(this.defaultInfo) }),
        tap(response => this.processInfo(response)),
        catchError(error => observableThrowError(error.message || error)));
  }

  // Store the geolocation information
  private processInfo(data) {
    this.info = data;

    // Set the currency found using the country code
    this.info.currency = this.localStorageService.getItem('currency') || Currencies.getCurrency4CountryCode(data.country_code);
  }
}
